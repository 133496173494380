<template>
  <div>
    <Pane :bottom="0"
          :showRemarks="false">
      <a-tabs v-model="activeKey"
              :tabBarStyle="{
          margin: '0',
        }">
        <a-tab-pane v-for="item in tabList"
                    :key="item.key"
                    :tab="item.name"
                    :disabled="item.disabled"></a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <ExportDownload :isView="isView"
                        :url="imgUrl"
                        @exportForm="exportForm"
                        @download="download" />
      </template>
    </Pane>

    <div class="container">
      <Claim ref="claimRef"
             v-show="activeKey === '0' && pData.projectName"
             :msg="pData"></Claim>
      <CounterClaim ref="counterClaimRef"
                    v-show="activeKey === '1' && pData.projectName"
                    :msg="pData"></CounterClaim>
    </div>
  </div>
</template>

<script>
import ExportDownload from "@/views/epc/components/ExportDownload";
import Claim from "./components/Claim";
import CounterClaim from "./components/CounterClaim";

import { fetchDetail } from "@/api/epc";

export default {
  name: "construClaim",
  components: {
    ExportDownload,
    Claim,
    CounterClaim,
  },
  data() {
    return {
      imgUrl: require("@/assets/epc/approvalFlow/4-6claim.png"),

      activeKey: "0",
      tabList: [
        { key: "0", name: "索赔审批", disabled: false },
        { key: "1", name: "反索赔审批", disabled: false },
      ],

      form: this.$form.createForm(this),
      pid: "", // 项目id
      pData: {},

      isView: false,
    };
  },
  watch: {
    activeKey: {
      handler(newV) {
        if (newV === "0") {
          this.imgUrl = require("@/assets/epc/approvalFlow/4-6claim.png");
        } else if (newV === "1") {
          this.imgUrl = require("@/assets/epc/approvalFlow/4-9counterclaim.png");
        }
      },
      immediate: true,
    },
  },
  mounted() {
    const { query } = this.$route;
    const { activeKey, pid, id, isView } = query || {}; // 通过activeKey判断当前是哪个tab，id判断是否是编辑页面，isView判断是否是查看页面，

    if (!pid) {
      return;
    }
    this.activeKey = activeKey || "0";
    this.pid = pid;
    this.isView = Boolean(isView) || false;

    if (id) {
      this.setTab();
    }
    this.getProjectInfo();
  },
  methods: {
    setTab() {
      // 从编辑进入页面，设置非活跃状态的tab不可用
      this.tabList.forEach((item) => {
        if (item.key !== this.activeKey) {
          item.disabled = true;
        }
      });
    },
    getProjectInfo() {
      fetchDetail(this.pid).then((res) => {
        this.pData = {
          pid: this.pid,
          designCode: res.designCode,
          projectName: res.name,
        };
      });
    },

    download() {
      switch (this.activeKey) {
        case "0":
          this.$refs.claimRef.download();
          break;
        case "1":
          this.$refs.counterClaimRef.download();
          break;
      }
    },
    exportForm() {
      switch (this.activeKey) {
        case "0":
          this.$refs.claimRef.exportForm();
          break;
        case "1":
          this.$refs.counterClaimRef.exportForm();
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
