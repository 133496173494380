var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"当前项目"}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.msg.designCode}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"符合审计原则"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'suitAudit',
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择',
                    },
                  ],
                }
              ]),expression:"[\n                'suitAudit',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请选择',\n                    },\n                  ],\n                }\n              ]"}]},[_c('a-radio',{attrs:{"value":"1"}},[_vm._v(" 是 ")]),_c('a-radio',{attrs:{"value":"0"}},[_vm._v(" 否 ")])],1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"工程名称","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.msg.projectName}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"反索赔情况说明","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'claimStatement',
            { rules:[{ required: true, message: '请输入' }]}
            ]),expression:"[\n            'claimStatement',\n            { rules:[{ required: true, message: '请输入' }]}\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("附件")]),_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'file',
              { rules:[{ required: true, message: '请上传附件' }]}
              ]),expression:"[\n              'file',\n              { rules:[{ required: true, message: '请上传附件' }]}\n              ]"}],staticClass:"link-list"},[_c('a-checkbox-group',{attrs:{"value":_vm.selectedFileList},on:{"change":_vm.onSelectFile}},_vm._l((_vm.fileList),function(item){return _c('div',{key:item.id,staticClass:"link"},[_c('a-checkbox',{attrs:{"value":item.id}},[_c('a',{attrs:{"target":"_blank","download":"","href":item.completePath}},[_vm._v(_vm._s(item.name))])])],1)}),0),_c('div',{staticClass:"control-bar"},[_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('div',{staticClass:"control"},[_c('a-icon',{attrs:{"type":"upload"}})],1)]),_c('div',{staticClass:"control",on:{"click":_vm.deleteFile}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)],1)])],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("提交")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }